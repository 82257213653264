import React, { useEffect, useState } from "react"
import Helmet from "react-helmet"

import VectorSub from "../../images/Vector-sub.svg"
import GroupSub from "../../images/Group-sub.svg"

const RightSection = ({ categoryID }) => {
  const [formLoaded, setFormStatus] = useState(false)
  useEffect(() => {
    // if (window && window.efwCreate) loadScript()
  }, [])

  useEffect(() => {
    loadRedTrack()
  }, [])

  const loadRedTrack = () => {
    let params = new URLSearchParams(window.location.search)
    if (params.get("rtClickID")) {
      setCookie("rtkclickid-store", params.get("rtClickID"), 1)

      loadScript(categoryID, params.get("rtClickID"))
    } else {
      loadUniversalScript()
    }
  }

  const loadUniversalScript = () => {
    console.log("loadUniversalScript")
    const script = document.createElement("script")
    script.id = "__script__id__"
    script.type = "text/javascript"
    script.async = true
    script.onload = () => {
      console.log("cookieCurrent")
      let intr = setInterval(() => {
        let cookieCurrent = getCookie("rtkclickid-store")
        if (cookieCurrent) {
          loadScript(categoryID, getCookie("rtkclickid-store"))
          clearInterval(intr)
        }
      }, 100)
    }
    script.src =
      "https://track.imgtrx2.com/uniclick.js?attribution=lastpaid&cookiedomain=&cookieduration=90&defaultcampaignid=63e1b85eebbaae00013b8255&regviewonce=false"
    document.body.append(script)
    console.log("SCRIPT APPENDED")
  }

  const getCookie = name => {
    let cookie = {}
    document.cookie.split(";").forEach(function (el) {
      let [k, v] = el.split("=")
      cookie[k.trim()] = v
    })
    return cookie[name]
  }

  const setCookie = (name, value) => {
    var expires = ""
    var date = new Date()
    date.setTime(date.getTime() + 1 * 24 * 60 * 60 * 1000)
    expires = "; expires=" + date.toUTCString()
    document.cookie = name + "=" + (value || "") + expires + "; path=/"
  }

  const loadScript = () => {
    if (window && window.efwCreate && categoryID && !formLoaded) {
      window.efwCreate({
        targetElementId: "formTargetDiv",
        campaignId: "C9068F62-EF67-45C4-B85E-FEE563F475E6",
        category: categoryID,
        valueProp: true,
        redirectUrl: "",
        autoResize: true,
        skin: "4",
        affData1: "",
        affData2: "",
        affData3: "",
        affData4: "",
        affData5: "",
      })
      setFormStatus(true)
      setTimeout(setScroll, 4000)
    }
  }

  const setScroll = () => {
    if (
      window &&
      window.document &&
      window.document.getElementsByTagName("iframe")
    ) {
      window.document
        .getElementsByTagName("iframe")[0]
        .setAttribute("scrolling", "yes")
    }
  }

  return (
    <>
      <div className="col-md-5 col-lg-5 col-xs-12 col-sm-12 right-section">
        <Helmet>
          <script
            src="https://efw.360connect.com/widget.js?v=3"
            onLoad={loadScript}
          />
          <script>
            {`function efwEventCallback(event, value){
              console.log(event,value)
              
              if(event==='conversion'){
                var value = '; '+document.cookie;
                var parts = value.split("; "+"rtkclickid-store"+"=");
                var clickId =parts.pop().split(';').shift();

                // var clickId = document.cookie.match(new RegExp('(^| )' + "rtkclickid-store" + '=([^;]+)'));
                console.log('conversion done',clickId)
                
                var node = document.createElement("img");
                node.src="https://track.imgtrx2.com/postback?clickid="+clickId;
                node.id="SB-Img"
                node.style="display:none !important";
                document.body.appendChild(node);
              }
              }`}
          </script>
        </Helmet>
        <div className="card right-card" id="formTargetDiv"></div>
      </div>
      <div className="px-2 mt-3 px-sm-5 d-flex flex-column d-md-none">
        <div className="d-flex">
          <img className=" ml-0 mr-2" alt="vecto-sub" src={VectorSub} />
          <p className="mb-0">
            <small>
              Compare Multiple Rates, so you know you’re getting the best deal.
            </small>
          </p>
        </div>
        <div className="d-flex">
          <img className=" ml-0 mr-2" alt="group-sub" src={GroupSub} />
          <p className="mb-0">
            <small>
              Compare quotes on essential services and equipment for your home
              or business.
            </small>
          </p>
        </div>
      </div>
    </>
  )
}

export default RightSection
