import React from "react"

import Image from ".././image"

import VectorSub from "../../images/Vector-sub.svg"
import GroupSub from "../../images/Group-sub.svg"
import pic2 from "../../images/Vector-1.svg"

const LeftSection = props => (
  <div className="col-md-7 col-lg-7 col-xs-12 col-sm-12  left-section">
    <div>
      <p className="sub-left-1 w-100">{props.title}</p>
    </div>
    <div className="sub-left-div">
      <p className="sub-left-2">FILL OUT A QUICK 2 MINUTE FORM</p>
    </div>
    <div className="px-2 mt-3 flex-column d-none d-md-flex">
      <div className="d-flex">
        <img className=" ml-0 mr-2" alt="vecto-sub" src={VectorSub} />
        <p className="mb-0">
          <small>
            Compare Multiple Rates, so you know you’re getting the best deal.
          </small>
        </p>
      </div>
      <div className="d-flex">
        <img className=" ml-0 mr-2" alt="group-sub" src={GroupSub} />
        <p className="mb-0">
          <small>
            Compare quotes on essential services and equipment for your home or
            business.
          </small>
        </p>
      </div>
    </div>
    <div className="sub-left-5">
      <Image className="controll-logo" src={props.image}></Image>
      <img className="Vector-logo" alt="VectorSub" src={pic2} />
    </div>
  </div>
)

export default LeftSection
