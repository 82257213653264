import React from "react"

import Card from "./howItWorksComponents/cards"

import logo from "../../images/how-it-works-img.svg"
import logo1 from "../../images/search-img.svg"
import logo2 from "../../images/cat-img.svg"
import logo3 from "../../images/clock-img.svg"

const HowItWorks = () => {
  return (
    <div className="how-it-works-main pb-5">
      <div className="container-fluid d-flex justify-content-center align-items-center flex-column">
        <div className="row d-flex justify-content-center align-items-center flex-column my-4">
          <div className="col d-flex justify-content-center">
            <img className="main-logo" alt="main-logo" src={logo} />
          </div>
          <div className="col d-flex justify-content-center">
            <p className="main-heading">How it Works?</p>
          </div>
        </div>
        <div className="container row">
          <div className="col-xs-12 col-lg-4 cards-column">
            <Card
              title="Research"
              img={logo1}
              content="By reading our buyer's advice, you can research your purchase. 
              Our comprehensive buying price will educate you about the purchase."
            />
          </div>
          <div className="col-xs-12 col-lg-4 cards-column">
            <Card
              title="Get Price Quotes"
              img={logo2}
              content="By filling the above form, you can get a quick price quote. 
              We help you shop from best vendors by letting you compare prices from professionals."
            />
          </div>
          <div className="col-xs-12 col-lg-4 cards-column">
            <Card
              title="Save Time & Money"
              img={logo3}
              content="Eventually, you will be saving a lot of time in making your best purchase. 
              This let you connect to best vendors real quick, and all by staying home."
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default HowItWorks
